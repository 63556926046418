import React, { useEffect, useState } from "react";
import { Avatar, Button, message, Spin } from "antd";
import styled from "styled-components";
import { requestAuthApi } from "../../libs/api/RequestAPI";

const Wrapper = styled.div`
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

interface UserProfile {
  sub: string;
  name: string;
  picture: string;
  exp: number;
}

const ProfileMenu = () => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const checkAuth = async () => {
    try {
      const response = await requestAuthApi.checkApiV1AuthCheckGet();
      if (response.data.status === "authenticated") {
        const userResponse = await requestAuthApi.meApiV1AuthMeGet();

        setUser(userResponse.data);
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setAuthChecked(true);
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await requestAuthApi.logoutApiV1AuthLogoutDelete();

      message.success("로그아웃되었습니다.");
      window.location.href = "/";
    } catch (error) {
      message.error("로그아웃에 실패하였습니다.");
    }
  };

  if (!authChecked) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Wrapper>
      <div style={{ float: "right" }}>
        {user ? (
          <>
            <Avatar src={user.picture} />
            <span style={{ color: "#fff", marginLeft: 8 }}>{user.name}</span>
            <Button
              type="link"
              onClick={handleLogout}
              style={{ color: "#fff", marginLeft: 16 }}
            >
              로그아웃
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};

export default ProfileMenu;
