import React from "react";
import { Modal, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import { CreateUserScheme } from "../../../libs/api/data-contracts";

interface AddFileModalProps {
  organizationId: string;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const AddUserModal: React.FC<AddFileModalProps> = ({
  organizationId,
  visible,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const newUser: CreateUserScheme = {
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
        };
        requestAuthApi
          .addUserApiV1InsilicolabOrganizationOrganizationIdPost(
            values.organization_id,
            newUser,
          )
          .then(() => {
            onOk();
            form.resetFields();
            window.location.reload();
          })
          .catch((error) => {
            console.log("사용자 생성 실패:", error);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="새로운 사용자 추가"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="addUserForm">
        <Form.Item
          name="organization_id"
          hidden={true}
          initialValue={organizationId}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-Mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "사용자 이메일 정보를 입력해 주세요" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="성"
          rules={[{ required: true, message: "사용자 성을 입력해 주세요" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="이름"
          rules={[{ required: true, message: "사용자명을 입력해 주세요" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="비밀번호"
          rules={[{ required: true, message: "비밀번호를 입력해 주세요" }]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
