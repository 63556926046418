import { Layout } from "antd";
import styled from "styled-components";

const FooterWrapper = Layout.Footer;
const FooterLink = styled.a`
  padding-left: 30px;
  padding-right: 30px;
  color: #666;
`;

const Footer = () => {
  return (
    <FooterWrapper
      style={{
        textAlign: "center",
      }}
    >
      <FooterLink
        href="https://miro.raiport.co.kr"
        rel="noopener noreferrer"
        target="_blank"
      >
        MIRO
      </FooterLink>
      <FooterLink
        href="https://portrai.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        Portrai.io
      </FooterLink>
      <FooterLink
        href="https://insilicolab.portrai.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        InSilicoLab
      </FooterLink>
      <FooterLink
        href="https://moni.raiport.co.kr"
        rel="noopener noreferrer"
        target="_blank"
      >
        Monitoring
      </FooterLink>
    </FooterWrapper>
  );
};

export default Footer;
