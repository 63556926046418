import React, { useEffect, useState } from "react";
import { List, Button, Card, message } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import { AllCategoryScheme } from "../../../libs/api/data-contracts";
import CategoryModal from "./CategoryModal";

const initCat: AllCategoryScheme[] = [
  {
    key: 1,
    parent_id: 0,
    depth: 0,
    is_enable: true,
    name: "",
  },
  {
    key: 2,
    parent_id: 0,
    depth: 0,
    is_enable: false,
    name: "",
  },
];

const listStyle = [
  { alignItems: "center", width: "600px" },
  { alignItems: "center", width: "400px" },
  { alignItems: "center", width: "200px" },
];
const CategoryManagement: React.FC = () => {
  const [categories, setCategories] = useState<AllCategoryScheme[]>(initCat);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<AllCategoryScheme>();

  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const response =
          await requestAuthApi.getAllCategoryApiV1InsilicolabCategoryGet();
        setCategories(response.data);
      } catch (error) {
        message.error("category 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    getCategoryList();
  }, []);

  const onCategorySelect = (category: AllCategoryScheme) => {
    setSelectedCategory(category);
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const renderCategoryList = (categories: AllCategoryScheme[]) => {
    if (categories === null) {
      return;
    }
    return categories.map((category) => (
      <List.Item
        key={category.key}
        style={
          category.depth === 0
            ? listStyle[0]
            : category.depth === 1
              ? listStyle[1]
              : listStyle[2]
        }
      >
        {category.is_enable ? (
          <Button
            variant="link"
            color="primary"
            style={{ fontSize: "16px" }}
            onClick={() => onCategorySelect(category)}
          >
            {category.name}
          </Button>
        ) : (
          <Button
            variant="link"
            color="default"
            style={{ fontSize: "16px", color: "gray" }}
            onClick={() => onCategorySelect(category)}
          >
            {category.name}
          </Button>
        )}

        {category.subCategories && (
          <div>{renderCategoryList(category.subCategories)}</div>
        )}
      </List.Item>
    ));
  };

  return (
    <div style={{ alignItems: "center", width: "650px" }}>
      <Card>
        <List>{renderCategoryList(categories)}</List>
      </Card>

      <CategoryModal
        category={selectedCategory}
        drawerVisible={drawerVisible}
        handleDrawerClose={handleDrawerClose}
      />
    </div>
  );
};

export default CategoryManagement;
