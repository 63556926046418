import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { requestAuthApi } from "./libs/api/RequestAPI";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import Breadcrumbs from "./layouts/Breadcrumb";
import Content from "./layouts/Content";
import Footer from "./layouts/Footer";
import Login from "./pages/Login";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const checkAuth = async () => {
    try {
      const response = await requestAuthApi.checkApiV1AuthCheckGet();
      setAuthChecked(response.data.status === "authenticated");
    } catch (error) {}
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (authChecked) {
    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Layout
            style={{
              minHeight: "100vh",
            }}
          >
            <Header />
            <Layout>
              <Sidebar />
              <Layout style={{ padding: "0 24px" }}>
                <Breadcrumbs />
                <Content />
                <Footer />
              </Layout>
            </Layout>
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    );
  } else {
    return <Login />;
  }
};
export default App;
