import React, { useEffect, useState } from "react";
import { Layout, Menu, message } from "antd";
import { DashboardMenu, LabUserMenu, MiroMenu, StoreMenu } from "./SideMenuData";

const SideWrapper = Layout.Sider;

const Sidebar = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const setMenu = async () => {
      try {
        let menuItems: any[] = [];
        DashboardMenu.map((obj) => menuItems.push(obj));
        MiroMenu.map((obj) => menuItems.push(obj));
        LabUserMenu.map((obj) => menuItems.push(obj));
        StoreMenu.map((obj) => menuItems.push(obj));
        setData(menuItems);
      } catch (error) {
        message.error("메뉴 목록 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    setMenu();
  }, []);

  return (
    <>
      <SideWrapper collapsible>
        <Menu mode="inline" theme="dark" items={data} />
      </SideWrapper>
    </>
  );
};

export default Sidebar;
