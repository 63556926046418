import {
  DashboardOutlined,
  ExperimentOutlined,
  HddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

export const DashboardMenu = [
  {
    key: "sub",
    icon: <DashboardOutlined />,
    label: <Link to="/">Dashboard</Link>,
  },
];

export const LabUserMenu = [
  {
    key: "sub0",
    icon: <ExperimentOutlined />,
    label: "InSilicoLab",
    children: [
      {
        key: "01",
        label: <Link to="/insilicolab/user">User</Link>,
      },
      {
        key: "02",
        label: <Link to="/insilicolab/report">Report</Link>,
      },
      {
        key: "03",
        label: <Link to="/insilicolab/category">Category</Link>,
      },
    ],
  },
];

export const MiroMenu = [
  {
    key: "sub1",
    icon: <HddOutlined />,
    label: "MIRO",
    children: [
      {
        key: "11",
        label: <Link to="/miro/stdb">STDB</Link>,
      },
    ],
  },
];

export const StoreMenu = [
  {
    key: "sub3",
    icon: <HddOutlined />,
    label: "Storage",
    children: [
      {
        key: "31",
        label: <Link to="/storage/minio">MinIO</Link>,
      },
    ],
  },
];
