import { message } from "antd";
import { S3Client } from "@aws-sdk/client-s3";
import { saveAs } from "file-saver";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { requestAuthApi } from "../api/RequestAPI";

const streamToBlob = async (stream: ReadableStream): Promise<Blob> => {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    chunks.push(value);
  }

  return new Blob(chunks);
};

export const BuildClient = async () => {
  const response = await requestAuthApi.envApiV1AuthEnvGet();

  console.log(response.data);
  return new S3Client({
    region: "portrai",
    credentials: {
      accessKeyId: response.data.minio_access_key,
      secretAccessKey: response.data.minio_secret_key,
    },
    endpoint: response.data.minio_url,
    forcePathStyle: true,
  });
};

export const GetObject = async (bucketName: string, key: string) => {
  try {
    const s3Client = await BuildClient();
    if (!s3Client) {
      return;
    }
    const command = new GetObjectCommand({ Bucket: bucketName, Key: key });
    const response = await s3Client.send(command);

    if (response.Body instanceof ReadableStream) {
      const blob = await streamToBlob(response.Body);
      saveAs(blob, key);
    } else {
      message.error("다운로드 에러");
      throw new Error("Unexpected response body type");
    }
  } catch (error) {
    message.error("파일을 다운로드 받을 수 없습니다.");
    console.error("Error downloading file:", error);
  }
};
