import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import { CreateOrgScheme } from "../../../libs/api/data-contracts";

const { Option } = Select;

interface AddFileModalProps {
  visible: boolean;
  onOk: (file: CreateOrgItem) => void;
  onCancel: () => void;
}

interface CreateOrgItem {
  organization_name: string;
}

const AddOrganizationModal: React.FC<AddFileModalProps> = ({
  visible,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        const response =
          await requestAuthApi.countriesApiV1InsilicolabEnvironmentCountryGet();
        setItems(response.data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const org: CreateOrgScheme = {
          organization_name: values.organization_name,
          country: values.country,
        };
        requestAuthApi.addOrganizationApiV1InsilicolabOrganizationPost(org);
        onOk(org);
        form.resetFields();
        window.location.reload();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="새로운 조직 추가"
      open={visible}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="createOrgForm">
        <Form.Item
          name="organization_name"
          label="조직 이름"
          rules={[{ required: true, message: "조직 이름을 입력해 주세요" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="가입 국가"
          rules={[{ required: true, message: "국가 정보를 입력해 주세요" }]}
        >
          <Select showSearch loading={loading}>
            {items.map((item) => (
              <Option key={item.name} value={item.name}>
                {item.flag + " " + item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrganizationModal;
