import React, { useEffect, useState } from "react";
import { Button, Card, Table, message, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import UserList from "./UserList";
import AddOrganizationModal from "./AddOrganizationModal";
import AddUserModal from "./AddUserModal";

export const fetchUsers = async (organizationId: string): Promise<any[]> => {
  const response =
    await requestAuthApi.searchUsersApiV1InsilicolabOrganizationOrganizationIdGet(
      organizationId,
      { page: 0, size: 1000 },
    );

  return new Promise((resolve) => {
    resolve(response.data);
  });
};

const InSilicoLabUser: React.FC = () => {
  const [orgData, setOrgData] = useState<any[]>([]);
  const [userData, setUserData] = useState<{
    [key: string]: any[];
  }>({});
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [isAddOrgModalVisible, setIsOrgModalVisible] = useState(false);
  const [isAddUserModalVisible, setIsUserModalVisible] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string>("");

  useEffect(() => {
    const getOrganizationList = async () => {
      try {
        const response =
          await requestAuthApi.searchOrganizationsApiV1InsilicolabOrganizationGet();
        setOrgData(response.data);
      } catch (error) {
        message.error("사용자 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };

    getOrganizationList();
  }, []);

  const showUserModal = (organizationId: string) => {
    setSelectedOrg(organizationId);
    setIsUserModalVisible(true);
  };

  const handleUserCancel = () => {
    setIsUserModalVisible(false);
    setSelectedOrg("");
  };

  const handleUserOk = () => {
    setIsUserModalVisible(false);
    setSelectedOrg("");
  };

  const showOrgModal = () => {
    setIsOrgModalVisible(true);
  };

  const handleOrgCancel = () => {
    setIsOrgModalVisible(false);
  };

  const handleOrgOk = (newFile: any) => {
    setIsOrgModalVisible(false);
  };

  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded && !userData[record.key]) {
      setLoading((prev) => ({ ...prev, [record.key]: true }));
      const users = await fetchUsers(record.organization_id);
      setUserData((prev) => ({ ...prev, [record.key]: users }));
      setLoading((prev) => ({ ...prev, [record.key]: false }));
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "조직명",
      dataIndex: "organization_name",
      key: "organization_name",
    },
    {
      title: "DISCOVERY",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"blue"}>{reportInfo.DISCOVERY}</Tag>,
    },
    {
      title: "TARGET",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"cyan"}>{reportInfo.TARGET}</Tag>,
    },
    {
      title: "DRUG",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"geekblue"}>{reportInfo.DRUG}</Tag>,
    },
    {
      title: "MIX",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"volcano"}>{reportInfo.MIX}</Tag>,
    },
    {
      title: "MOA",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"purple"}>{reportInfo.MOA}</Tag>,
    },
    {
      title: "TME",
      dataIndex: "count",
      key: "count",
      align: "right",
      render: (reportInfo) => <Tag color={"blue"}>{reportInfo.TME}</Tag>,
    },
    {
      title: "",
      dataIndex: "organization_id",
      key: "organization_id",
      align: "right",
      render: (organization_id) => (
        <Button onClick={() => showUserModal(organization_id)}>
          사용자 추가
        </Button>
      ),
    },
  ];

  return (
    <Card
      title={"사용자 목록"}
      extra={
        <Button type="primary" onClick={showOrgModal}>
          조직추가
        </Button>
      }
    >
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <UserList
              users={userData[record.key]}
              loading={loading[record.key]}
            />
          ),
          onExpand: handleExpand,
        }}
        dataSource={orgData}
        pagination={false}
      />
      <AddOrganizationModal
        visible={isAddOrgModalVisible}
        onOk={handleOrgOk}
        onCancel={handleOrgCancel}
      />
      <AddUserModal
        organizationId={selectedOrg}
        visible={isAddUserModalVisible}
        onOk={handleUserOk}
        onCancel={handleUserCancel}
      />
    </Card>
  );
};

export default InSilicoLabUser;
