import React, { useEffect, useState } from "react";
import { Button, Card, message, Table, Tag, Typography } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import RAWUploadModal from "./RAWUploadModal";
import moment from "moment/moment";
import ExpireDateModal from "./ExpireDateModal";

const { Text } = Typography;

const InSilicoLabReport: React.FC = () => {
  const [isExpireModalVisible, setExpireModalVisible] = useState(false);
  const [expireReportId, setExpireReportId] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  useEffect(() => {
    searchReports(pagination.current, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const searchReports = async (page: any, pageSize: any) => {
    setLoading(true);
    try {
      const response =
        await requestAuthApi.searchReportListApiV1InsilicolabReportGet({
          page: page,
          size: pageSize,
        });

      setData(response.data.labs);
      setPagination({
        ...pagination,
        total: response.data.count,
      });
    } catch (error) {
      message.error("리포트 목록을 조회하는데 실패 했습니다.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const showExpireModal = (value: any) => {
    setExpireReportId(value);
    setExpireModalVisible(true);
  };

  const closeExpireModal = () => {
    setExpireModalVisible(false);
  };

  const TableColumns: ColumnsType<any> = [
    {
      title: "조직정보",
      dataIndex: "organization_name",
      key: "organization_name",
    },
    {
      title: "Species",
      dataIndex: "species",
      key: "species",
      render: (species) => <Tag bordered={false}>{species}</Tag>,
    },
    {
      title: "Organ",
      dataIndex: "organ",
      key: "organ",
      render: (organ) => <Tag bordered={false}>{organ}</Tag>,
    },
    {
      title: "Bio Status",
      dataIndex: "bio_status",
      key: "bio_status",
      render: (bio_status) => <Tag bordered={false}>{bio_status}</Tag>,
    },
    {
      title: "서비스타입",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag
          color={
            type === "TME"
              ? "blue"
              : type === "TARGET"
                ? "cyan"
                : type === "MIX"
                  ? "geekblue"
                  : type === "MOA"
                    ? "purple"
                    : type === "DRUG"
                      ? "volcano"
                      : "green"
          }
          key={type}
        >
          {type}
        </Tag>
      ),
    },
    {
      title: "프로젝트명",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "진행상태",
      dataIndex: "process_status",
      key: "process_status",
      render: (process_status) => (
        <Tag
          color={
            process_status === "COMPLETED"
              ? "blue"
              : process_status === "PROCESS_FAILED"
                ? "red"
                : "green"
          }
          key={process_status}
        >
          {process_status}
        </Tag>
      ),
    },
    {
      title: "활성화상태",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "ACTIVE" ? "blue" : "magenta"} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: "생성일",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <Text>{moment(created_at).utc().format("YYYY-MM-DD HH:mm:ss")}</Text>
      ),
    },
    {
      title: "만료일",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (expired_at) => (
        <Text>{moment(expired_at).utc().format("YYYY-MM-DD HH:mm:ss")}</Text>
      ),
    },
    {
      title: "종료 기간 변경",
      dataIndex: "lab_id",
      key: "lab_id",
      render: (lab_id) => (
        <Button onClick={() => showExpireModal(lab_id)}>종료기간변경</Button>
      ),
    },
  ];

  return (
    <>
      <Card
        title={"Interactive Report"}
        extra={
          <>
            <Button type="primary" onClick={showModal}>
              Upload Report Data
            </Button>
            <RAWUploadModal isVisible={isModalVisible} onClose={closeModal} />
          </>
        }
      >
        <Table
          columns={TableColumns}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
      <ExpireDateModal
        isVisible={isExpireModalVisible}
        reportId={expireReportId}
        onClose={closeExpireModal}
      />
    </>
  );
};

export default InSilicoLabReport;
