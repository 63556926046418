import React, { useState } from "react";
import { Table, Spin, Typography, Switch, Button, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment/moment";
import ResetUserPasswordModal from "./ResetUserPasswordModal";
import { requestAuthApi } from "../../../libs/api/RequestAPI";

const { Text } = Typography;

interface UserListProps {
  users: any[] | undefined;
  loading: boolean;
}

const UserList: React.FC<UserListProps> = ({ users, loading }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const showResetPasswordModal = (object: any) => {
    setSelectedUserId(object.user_id);
    setSelectedOrgId(object.organization_id);
    setIsModalVisible(true);
  };

  const closeResetPasswordModal = () => {
    setSelectedOrgId(null);
    setSelectedUserId(null);
    setIsModalVisible(false);
    window.location.reload();
  };
  const handleActivateChange = async (record: any) => {
    try {
      await requestAuthApi.removeUserApiV1InsilicolabOrganizationOrganizationIdUserIdDelete(
        record.organization_id,
        record.user_id,
      );
      window.location.reload();
    } catch (error) {
      message.error("실행 오류");
    }
  };

  const userColumns: ColumnsType<any> = [
    {
      title: "사용자 이름",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id, user) => (
        <Text>
          {user.first_name} {user.last_name}
        </Text>
      ),
    },
    { title: "E-Mail", dataIndex: "email", key: "email" },
    {
      title: "비밀번호 변경",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id: string, object: any) => (
        <Button onClick={() => showResetPasswordModal(object)}>
          비밀번호 변경
        </Button>
      ),
    },
    {
      title: "활성화 상태",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          value={status === "ACTIVATE"}
          onChange={(checked) => handleActivateChange(record)}
        />
      ),
    },
    {
      title: "마지막 접속 시간",
      dataIndex: "last_login_at",
      key: "last_login_at",
      render: (last_login_at) =>
        last_login_at === null ? (
          <Text>로그인 기록 없음</Text>
        ) : (
          <Text>
            {moment(last_login_at).utc().format("YYYY-MM-DD HH:mm:ss")}
          </Text>
        ),
    },
    {
      title: "생성 시간",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <Text>{moment(created_at).utc().format("YYYY-MM-DD HH:mm:ss")}</Text>
      ),
    },
  ];

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <Table columns={userColumns} dataSource={users} pagination={false} />
      <ResetUserPasswordModal
        visible={isModalVisible}
        organizationId={selectedOrgId}
        userId={selectedUserId}
        onClose={closeResetPasswordModal}
      />
    </>
  );
};

export default UserList;
