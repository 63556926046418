import React, { useEffect, useState } from "react";
import { Table, Button, Card, message } from "antd";
import { UpCircleFilled, DownCircleFilled } from "@ant-design/icons";
import InputSTDBForm from "./InputSTDBForm";
import { STDBColumn } from "./ISTDB";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import {
  AnBlockScheme,
  AnonCompanyResponseScheme,
  AnonDiseaseResponseScheme,
  AnonGenePanelResponseScheme,
  AnonGeneProcessResponseScheme,
  AnonPlatformScheme,
  AnonProjectResponseScheme,
  AnonSubjectResponseScheme,
} from "../../../libs/api/data-contracts";
import type { TablePaginationConfig } from "antd/es/table";

const SpatialDB: React.FC = () => {
  const [spatialData, setSpatialData] = useState<AnBlockScheme[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<
    AnonCompanyResponseScheme[]
  >([]);

  const [projectOptions, setProjectOptions] = useState<
    AnonProjectResponseScheme[]
  >([]);
  const [platformOptions, setPlatformOptions] = useState<AnonPlatformScheme[]>(
    [],
  );
  const [geneTypeOptions, setGeneTypeOptions] = useState<
    AnonGeneProcessResponseScheme[]
  >([]);
  const [genePanelOptions, setGenePanelOptions] = useState<
    AnonGenePanelResponseScheme[]
  >([]);
  const [diseaseOptions, setDiseaseOptions] = useState<
    AnonDiseaseResponseScheme[]
  >([]);
  const [subjectOptions, setSubjectOptions] = useState<
    AnonSubjectResponseScheme[]
  >([]);

  const getCompanyData = async () => {
    try {
      const response =
        await requestAuthApi.searchCompanyApiV1AnonymizedCompanyGet();
      setCompanyOptions(response.data);
    } catch (error) {
      message.error("Company 목록 조회 실패");
    }
  };

  const getProjectOptions = async (values: string) => {
    try {
      const response =
        await requestAuthApi.searchProjectApiV1AnonymizedProjectCompanyIdGet(
          values,
        );
      setProjectOptions(response.data);
    } catch (error) {
      message.error("Project 목록 조회 실패");
    }
  };

  const getPlatformOptions = async () => {
    try {
      const response =
        await requestAuthApi.searchPlatformApiV1AnonymizedPlatformGet();
      setPlatformOptions(response.data);
    } catch (error) {
      message.error("Platform 목록 조회 실패");
    }
  };

  const getGeneTypeOptions = async () => {
    try {
      const response =
        await requestAuthApi.searchGeneTypeApiV1AnonymizedGeneGet();
      setGeneTypeOptions(response.data);
    } catch (error) {
      message.error("Gene Type 목록 조회 실패");
    }
  };

  const getGenePanelOptions = async (typeId: string) => {
    try {
      const response =
        await requestAuthApi.searchGenePanelApiV1AnonymizedGeneGeneIdGet(
          typeId,
        );
      setGenePanelOptions(response.data);
    } catch (error) {
      message.error("Gene Panel 목록 조회 실패");
    }
  };

  const getSubjectOptions = async () => {
    try {
      const response =
        await requestAuthApi.searchSubjectApiV1AnonymizedSubjectGet();
      setSubjectOptions(response.data);
    } catch (error) {
      message.error("Subject 목록 조회 실패");
    }
  };

  const getDiseaseOptions = async () => {
    try {
      const response =
        await requestAuthApi.searchDiseaseApiV1AnonymizedDiseaseGet();
      setDiseaseOptions(response.data);
    } catch (error) {
      message.error("Disease 목록 조회 실패");
    }
  };

  const getSpatialDataList = async () => {
    try {
      const response =
        await requestAuthApi.searchBlockApiV1AnonymizedBlockGet();
      setSpatialData(response.data.labs);

      setPagination({
        ...pagination,
        total: response.data.count,
      });
    } catch (error) {
      message.error("STDB 목록 조회 실패");
    } finally {
      setLoading(false);
    }
  };

  const getSTDB = async () => {
    await getCompanyData();
    await getProjectOptions("none");
    await getPlatformOptions();
    await getGeneTypeOptions();
    await getGenePanelOptions("none");
    await getSubjectOptions();
    await getDiseaseOptions();
    await getSpatialDataList();
  };

  useEffect(() => {
    getSTDB();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleFinish = async (values: any) => {
    await requestAuthApi.addBlockApiV1AnonymizedBlockPut(values);
    await getSpatialDataList();
    setIsEditing(false);
  };

  const handleAddOption = async (type: string, values: any) => {
    if (type === "disease") {
      await requestAuthApi.addDiseaseApiV1AnonymizedDiseasePut(values);
      await getDiseaseOptions();
    } else if (type === "subject") {
      await requestAuthApi.addSubjectApiV1AnonymizedSubjectPut(values);
      await getSubjectOptions();
    } else if (type === "genePanel") {
      await requestAuthApi.addGenePanelApiV1AnonymizedGeneGeneIdPut(
        values.geneType,
        {
          name: values.name,
          gene_count: values.count,
          description: values.note,
        },
      );
      await getGenePanelOptions(values.geneType);
    } else if (type === "geneType") {
      await requestAuthApi.addGeneTypeApiV1AnonymizedGenePut(values);
      await getGeneTypeOptions();
    } else if (type === "project") {
      await requestAuthApi.addProjectApiV1AnonymizedProjectCompanyIdPut(
        values.company,
        {
          name: values.name,
          code: values.code,
          description: values.description,
        },
      );
      await getProjectOptions(values.company);
    } else if (type === "company") {
      await requestAuthApi.addCompanyApiV1AnonymizedCompanyPut(values);
      await getCompanyData();
    } else if (type === "companyUpdate") {
      await getProjectOptions(values);
    } else if (type === "geneTypeUpdate") {
      await getGenePanelOptions(values);
    }
  };

  return (
    <Card
      title={"Spatial DataBase"}
      extra={
        <Button
          icon={isEditing ? <UpCircleFilled /> : <DownCircleFilled />}
          type="primary"
          onClick={handleEditClick}
        >
          정보 추가
        </Button>
      }
    >
      {isEditing && (
        <Card style={{ marginBottom: 32 }}>
          <InputSTDBForm
            companyOptions={companyOptions}
            projectOptions={projectOptions}
            platformOptions={platformOptions}
            geneTypeOptions={geneTypeOptions}
            genePanelOptions={genePanelOptions}
            diseaseOptions={diseaseOptions}
            subjectOptions={subjectOptions}
            onFinish={handleFinish}
            onAddOption={handleAddOption}
          />
        </Card>
      )}
      <Table
        columns={STDBColumn}
        dataSource={spatialData}
        pagination={pagination}
        loading={loading}
      />
    </Card>
  );
};

export default SpatialDB;
