import React from "react";
import { Form, Modal, DatePicker, Space, Button, message } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";

interface ExpireModalProps {
  isVisible: boolean;
  reportId: string;
  onClose: () => void;
}
const config = {
  rules: [
    {
      type: "object" as const,
      required: true,
      message: "종료 시간을 입력해 주세요",
    },
  ],
};
const ExpireDateModal: React.FC<ExpireModalProps> = ({
  isVisible,
  reportId,
  onClose,
}) => {
  const onFinish = async (values: any) => {
    try {
      const formattedDate = values.datetime.format("YYYY-MM-DD HH:mm:ss");
      await requestAuthApi.updateEndDateApiV1InsilicolabReportReportReportIdPost(
        reportId,
        { expire_date: formattedDate },
      );
      window.location.reload();
    } catch (error) {
      message.error("Interactive Report 등록 실패");
      console.error("Failed to fetch data", error);
    }
  };

  return (
    <Modal
      title="종료 기간 변경"
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        name="set_expire_date"
        onFinish={onFinish}
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <Form.Item name="datetime" label="조회 종료 시간" {...config}>
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              적용
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExpireDateModal;
