import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Button, Card, Table, Row, Col, Layout, message } from "antd";
import {
  FileOutlined,
  FolderOpenOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  FileAddOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import styled from "styled-components";
import { partial } from "filesize";
import moment from "moment";
import { requestAuthApi } from "../../../libs/api/RequestAPI";

import MinIODownload from "./MinIODownload";
import MinIODelete from "./MinIODelete";
import MinIOUpload from "./MinIOUpload";
import MinIOUploadFile from "./MinIOUploadFile";

const size = partial({ standard: "jedec" });

const ContentWrapper = styled(Layout.Content)`
  padding: 6px;
`;

const columns: TableProps<any>["columns"] = [
  {
    title: "Type",
    dataIndex: "is_dir",
    key: "is_dir",
    width: 60,
    render: (is_dir: boolean) =>
      is_dir ? <FolderOpenOutlined /> : <FileOutlined />,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name: string, record: any) =>
      record.is_dir ? (
        <Link to={`/storage/minio/${record.bucket}/${record.prefix}${name}`}>
          {name}
        </Link>
      ) : (
        name
      ),
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    width: 120,
    render: (dataSize) => size(dataSize),
  },
  {
    title: "Last modified",
    dataIndex: "last_modified",
    key: "last_modified",
    width: 220,
    render: (last_modified) =>
      moment(last_modified).utc().format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Download",
    dataIndex: "name",
    key: "name",
    width: 120,
    render: (name: string, record: any) =>
      record.is_dir ? (
        <div />
      ) : (
        <Button
          onClick={() => {
            MinIODownload(record.bucket, record.prefix + record.name);
          }}
          type="text"
          icon={<CloudDownloadOutlined />}
        >
          Download
        </Button>
      ),
  },
  {
    title: "Delete",
    dataIndex: "name",
    key: "name",
    width: 120,
    render: (name: string, record: any) => (
      <Button
        onClick={() => {
          MinIODelete(record.bucket, record.prefix + record.name);
        }}
        type="text"
        icon={<DeleteOutlined />}
      >
        Delete
      </Button>
    ),
  },
];

const MinObject = () => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [bucketName, setBucketName] = useState<string>("");
  const [prefix, setPrefixPath] = useState<string>("");
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] =
    useState(false);
  const [isUploadDirectoryModalVisible, setIsUploadDirectoryModalVisible] =
    useState(false);

  const location = useLocation();

  useEffect(() => {
    const getBucketName = () => {
      const path = location.pathname.replace("/storage/minio/", "");
      const splitString = path.split("/");
      return splitString[0];
    };

    const getPrefixPath = () => {
      const path = location.pathname.replace("/storage/minio/", "");
      let prefix = path.split("/").slice(1).join("/");
      if (prefix.length < 1) {
        prefix = "/";
      }
      return prefix;
    };

    const getDirectoryList = async () => {
      try {
        const bucket = getBucketName();
        const prefix = getPrefixPath();
        const response = await requestAuthApi.getObjectListApiV1MinioObjectGet({
          bucket_name: bucket,
          prefix_path: prefix,
        });

        setFileList(response.data);
        setBucketName(bucket);
        setPrefixPath(prefix);
      } catch (error) {
        message.error("디렉토리 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };

    getDirectoryList();
  }, [location]);

  const showUploadFileModal = () => {
    setIsUploadFileModalVisible(true);
  };

  const closeUploadFileModal = () => {
    setIsUploadFileModalVisible(false);
    window.location.reload();
  };

  const showUploadDirectoryModal = () => {
    setIsUploadDirectoryModalVisible(true);
  };

  const closeUploadDirectoryModal = () => {
    setIsUploadDirectoryModalVisible(false);
    window.location.reload();
  };

  return (
    <Card
      title={bucketName}
      extra={
        <>
          <Button
            type="primary"
            onClick={showUploadFileModal}
            shape="circle"
            icon={<FileAddOutlined />}
          />{" "}
          <Button
            type="primary"
            onClick={showUploadDirectoryModal}
            shape="circle"
            icon={<FolderAddOutlined />}
          />
        </>
      }
    >
      <Row>
        <Col
          span={24}
          style={{
            height: "80vh",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <ContentWrapper>
            <Table columns={columns} dataSource={fileList} />
          </ContentWrapper>
        </Col>
      </Row>
      <MinIOUpload
        bucketName={bucketName}
        prefix={prefix}
        isVisible={isUploadDirectoryModalVisible}
        onClose={closeUploadDirectoryModal}
      />

      <MinIOUploadFile
        bucketName={bucketName}
        prefix={prefix}
        isVisible={isUploadFileModalVisible}
        onClose={closeUploadFileModal}
      />
    </Card>
  );
};

export default MinObject;
