import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Modal,
  InputNumber,
} from "antd";
import {
  AnonCompanyResponseScheme,
  AnonDiseaseResponseScheme,
  AnonGenePanelResponseScheme,
  AnonGeneProcessResponseScheme,
  AnonPlatformScheme,
  AnonProjectResponseScheme,
  AnonSubjectResponseScheme,
} from "../../../libs/api/data-contracts";

interface EditableFormProps {
  companyOptions: AnonCompanyResponseScheme[];
  projectOptions: AnonProjectResponseScheme[];
  platformOptions: AnonPlatformScheme[];
  geneTypeOptions: AnonGeneProcessResponseScheme[];
  genePanelOptions: AnonGenePanelResponseScheme[];
  diseaseOptions: AnonDiseaseResponseScheme[];
  subjectOptions: AnonSubjectResponseScheme[];
  onFinish: (values: any) => void;
  onAddOption: (type: string, values: any) => void;
}

const InputSTDBForm: React.FC<EditableFormProps> = ({
  companyOptions,
  projectOptions,
  platformOptions,
  geneTypeOptions,
  genePanelOptions,
  diseaseOptions,
  subjectOptions,
  onFinish,
  onAddOption,
}) => {
  const [form] = Form.useForm();
  const [formCompany] = Form.useForm();
  const [formProject] = Form.useForm();
  const [formGeneType] = Form.useForm();
  const [formGenePanel] = Form.useForm();
  const [formSubject] = Form.useForm();
  const [formDisease] = Form.useForm();

  const handleChangeCompany = (value: any) => {
    onAddOption("companyUpdate", value);
  };

  const handleChangeGeneType = (value: any) => {
    onAddOption("geneTypeUpdate", value);
  };

  const handleAddCompany = () => {
    Modal.confirm({
      title: `Add new company`,
      content: (
        <Form form={formCompany} layout="vertical">
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="description"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formCompany.validateFields();
        onAddOption("company", values);
        formCompany.resetFields();
      },
    });
  };

  const handleAddProject = () => {
    Modal.confirm({
      title: `Add new project`,
      content: (
        <Form form={formProject} layout="vertical">
          <Form.Item
            name="company"
            label="company"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a company">
              {companyOptions.map((option) => (
                <Select.Option key={option.key} value={option.company_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="description"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formProject.validateFields();
        onAddOption("project", values);
        formProject.resetFields();
      },
    });
  };

  const handleAddGeneType = () => {
    Modal.confirm({
      title: `Add new gene type`,
      content: (
        <Form form={formGeneType} layout="vertical">
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formGeneType.validateFields();
        onAddOption("geneType", values);
        formGeneType.resetFields();
      },
    });
  };

  const handleAddGenePanel = () => {
    Modal.confirm({
      title: `Add new gene panel`,
      content: (
        <Form form={formGenePanel} layout="vertical">
          <Form.Item
            name="geneType"
            label="gene type"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a gene type">
              {geneTypeOptions.map((option) => (
                <Select.Option key={option.key} value={option.gene_process_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="count" label="count" rules={[{ required: true }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="note" label="note" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formGenePanel.validateFields();
        onAddOption("genePanel", values);
        formGenePanel.resetFields();
      },
    });
  };

  const handleAddSubject = () => {
    Modal.confirm({
      title: `Add new subject`,
      content: (
        <Form form={formSubject} layout="vertical">
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="alias" label="alias" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formSubject.validateFields();
        onAddOption("subject", values);
        formSubject.resetFields();
      },
    });
  };

  const handleAddDisease = () => {
    Modal.confirm({
      title: `Add new disease`,
      content: (
        <Form form={formDisease} layout="vertical">
          <Form.Item name="name" label="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="classification"
            label="classification"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="alias" label="alias" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        const values = await formDisease.validateFields();
        onAddOption("disease", values);
        formDisease.resetFields();
      },
    });
  };

  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Row>
        <Col>
          <Form.Item
            name="company"
            rules={[{ required: true, message: "Please select a company" }]}
          >
            <Select
              placeholder="Select a company"
              onSelect={handleChangeCompany}
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddCompany()}>
                    Add Company
                  </Button>
                </div>
              )}
            >
              {companyOptions.map((option) => (
                <Select.Option key={option.key} value={option.company_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="project"
            rules={[{ required: true, message: "Please select a project" }]}
          >
            <Select
              placeholder="Select a project"
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddProject()}>
                    Add project
                  </Button>
                </div>
              )}
            >
              {projectOptions.map((option) => (
                <Select.Option key={option.key} value={option.project_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="platform"
            rules={[{ required: true, message: "Please select a platform" }]}
          >
            <Select placeholder="Select a platform" style={{ width: 240 }}>
              {platformOptions.map((option) => (
                <Select.Option key={option.key} value={option.platform_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="block_number" rules={[{ required: true }]}>
            <InputNumber
              placeholder="block number"
              min={0}
              style={{ width: 240 }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="gene_type"
            rules={[{ required: true, message: "Please select a gene type" }]}
          >
            <Select
              placeholder="Select a gene type"
              onChange={handleChangeGeneType}
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddGeneType()}>
                    Add gene type
                  </Button>
                </div>
              )}
            >
              {geneTypeOptions.map((option) => (
                <Select.Option key={option.key} value={option.gene_process_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="gene_panel"
            rules={[{ required: true, message: "Please select a gene panel" }]}
          >
            <Select
              placeholder="Select a gene panel"
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddGenePanel()}>
                    Add gene panel
                  </Button>
                </div>
              )}
            >
              {genePanelOptions.map((option) => (
                <Select.Option key={option.key} value={option.gene_panel_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: "Please select a subject" }]}
          >
            <Select
              placeholder="Select a subject"
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddSubject()}>
                    Add subject
                  </Button>
                </div>
              )}
            >
              {subjectOptions.map((option) => (
                <Select.Option key={option.key} value={option.subject_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="disease"
            rules={[{ required: true, message: "Please select a disease" }]}
          >
            <Select
              placeholder="Select a disease"
              style={{ width: 240 }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Button type="link" onClick={() => handleAddDisease()}>
                    Add disease
                  </Button>
                </div>
              )}
            >
              {diseaseOptions.map((option) => (
                <Select.Option key={option.key} value={option.disease_id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item>
            <Button
              type="primary"
              size={"large"}
              htmlType="submit"
              style={{ marginTop: 12 }}
            >
              신규 데이터 등록
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InputSTDBForm;
