import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { requestAuthApi } from "../../libs/api/RequestAPI";
interface UserProfile {
  sub: string;
  name: string;
  picture: string;
  exp: number;
}

const Dashboard = () => {
  const [user, setUser] = useState<UserProfile>({
    sub: "",
    name: "",
    picture: "",
    exp: 0,
  });

  const checkAuth = async () => {
    try {
      const response = await requestAuthApi.meApiV1AuthMeGet();
      setUser(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <div style={{ padding: "50px" }}>
      <div style={{ textAlign: "center" }}>
        <h1>환영합니다, {user.name}님!</h1>
        <Avatar size={128} src={user.picture} />
        <p>이메일: {user.sub}</p>
      </div>
    </div>
  );
};

export default Dashboard;
