import React, { useEffect } from "react";
import { Button, Drawer, Form, Input, Switch, Card } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import { AllCategoryScheme } from "../../../libs/api/data-contracts";

interface AddModelProps {
  category: AllCategoryScheme | undefined;
  drawerVisible: boolean;
  handleDrawerClose: () => void;
}

const CategoryModal: React.FC<AddModelProps> = ({
  category,
  drawerVisible,
  handleDrawerClose,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (drawerVisible && category) {
      form.setFieldsValue({
        name: category.name,
        is_enable: category.is_enable,
      });
    }
  }, [drawerVisible, category, form]);

  const onSubmit = async (values: any) => {
    if (category === undefined) {
      return;
    }
    const category_id = category.key;
    await requestAuthApi.updateCategoryApiV1InsilicolabCategoryCategoryIdPut(
      category_id,
      {
        name: values.name,
        is_enable: values.is_enable,
      },
    );
    // form.resetFields();
    window.location.reload();
  };

  return (
    <Drawer
      title="Edit category"
      size={"large"}
      open={drawerVisible}
      onClose={handleDrawerClose}
    >
      <Card>
        <Form
          form={form}
          name="category_form"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item
            label="카테고리명"
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={category?.name} />
          </Form.Item>
          <Form.Item
            label="활성화 상태"
            name="is_enable"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              수정
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  );
};

export default CategoryModal;
