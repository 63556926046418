import React from "react";
import { Button, Modal, Form, Input, Space } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const MinIOCreateBucket: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const onFinish = async (values: any) => {
    await requestAuthApi.addBucketApiV1MinioBucketPost({
      bucket_name: values.bucket,
    });
    window.location.reload();
  };

  return (
    <>
      <Modal
        title="Create Bucket"
        open={isVisible}
        onCancel={onClose}
        footer={null}
      >
        <Form
          name="validate_other"
          onFinish={onFinish}
          labelAlign="left"
          labelWrap
          colon={false}
        >
          <Form.Item
            name="bucket"
            label="Bucket Name"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MinIOCreateBucket;
