/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddBlockApiV1AnonymizedBlockPutData,
  AddBlockApiV1AnonymizedBlockPutError,
  AddBucketApiV1MinioBucketPostData,
  AddBucketApiV1MinioBucketPostError,
  AddCategoryApiV1InsilicolabCategoryPostData,
  AddCategoryApiV1InsilicolabCategoryPostError,
  AddCategoryScheme,
  AddCompanyApiV1AnonymizedCompanyPutData,
  AddCompanyApiV1AnonymizedCompanyPutError,
  AddDataApiV1CraftPutData,
  AddDiseaseApiV1AnonymizedDiseasePutData,
  AddDiseaseApiV1AnonymizedDiseasePutError,
  AddGenePanelApiV1AnonymizedGeneGeneIdPutData,
  AddGenePanelApiV1AnonymizedGeneGeneIdPutError,
  AddGeneTypeApiV1AnonymizedGenePutData,
  AddGeneTypeApiV1AnonymizedGenePutError,
  AddOrganizationApiV1InsilicolabOrganizationPostData,
  AddOrganizationApiV1InsilicolabOrganizationPostError,
  AddProjectApiV1AnonymizedProjectCompanyIdPutData,
  AddProjectApiV1AnonymizedProjectCompanyIdPutError,
  AddSubjectApiV1AnonymizedSubjectPutData,
  AddSubjectApiV1AnonymizedSubjectPutError,
  AddUserApiV1InsilicolabOrganizationOrganizationIdPostData,
  AddUserApiV1InsilicolabOrganizationOrganizationIdPostError,
  AdminApiV1AuthAdminPutData,
  AdminApiV1AuthAdminPutError,
  AnBlockRequestScheme,
  AnCompanyRequestScheme,
  AnonDiseaseRequestScheme,
  AnonGenePanelRequestScheme,
  AnonGeneProcessRequestScheme,
  AnonProjectRequestScheme,
  AnonSubjectRequestScheme,
  AuthApiV1AuthAuthGetData,
  BodyUploadFileApiV1InsilicolabReportFilePut,
  ChangePasswordScheme,
  ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPostData,
  ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPostError,
  CheckApiV1AuthCheckGetData,
  CountriesApiV1InsilicolabEnvironmentCountryGetData,
  CraftStatisticsApiV1CraftStatisticsGetData,
  CreateOrgScheme,
  CreateUserScheme,
  DeleteCategoryApiV1InsilicolabCategoryCategoryIdDeleteData,
  DeleteCategoryApiV1InsilicolabCategoryCategoryIdDeleteError,
  DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDeleteData,
  DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDeleteError,
  EnvApiV1AuthEnvGetData,
  GetAllCategoryApiV1InsilicolabCategoryGetData,
  GetBucketListApiV1MinioBucketGetData,
  GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGetData,
  GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGetError,
  GetDirectoryListApiV1MinioDirectoryBucketNameGetData,
  GetDirectoryListApiV1MinioDirectoryBucketNameGetError,
  GetObjectInfoApiV1MinioObjectInfoGetData,
  GetObjectInfoApiV1MinioObjectInfoGetError,
  GetObjectListApiV1MinioObjectGetData,
  GetObjectListApiV1MinioObjectGetError,
  GroupApiV1AuthGroupPutData,
  GroupApiV1AuthGroupPutError,
  GroupListApiV1AuthGroupGetData,
  LoginApiV1AuthLoginGetData,
  LogoutApiV1AuthLogoutDeleteData,
  MeApiV1AuthMeGetData,
  MinIOCreateBucketScheme,
  MinIOCreatePathScheme,
  PostCreateDirectoryApiV1MinioDirectoryPostData,
  PostCreateDirectoryApiV1MinioDirectoryPostError,
  PutAdminScheme,
  PutGroupScheme,
  RemoveObjectApiV1MinioObjectDeleteData,
  RemoveObjectApiV1MinioObjectDeleteError,
  RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDeleteData,
  RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDeleteError,
  ReportExpireScheme,
  SearchBlockApiV1AnonymizedBlockGetData,
  SearchBlockApiV1AnonymizedBlockGetError,
  SearchCompanyApiV1AnonymizedCompanyGetData,
  SearchCompanyApiV1AnonymizedCompanyGetError,
  SearchCraftApiV1CraftGetData,
  SearchDiseaseApiV1AnonymizedDiseaseGetData,
  SearchGenePanelApiV1AnonymizedGeneGeneIdGetData,
  SearchGenePanelApiV1AnonymizedGeneGeneIdGetError,
  SearchGeneTypeApiV1AnonymizedGeneGetData,
  SearchOrganizationsApiV1InsilicolabOrganizationGetData,
  SearchOrganizationsApiV1InsilicolabOrganizationGetError,
  SearchPlatformApiV1AnonymizedPlatformGetData,
  SearchProjectApiV1AnonymizedProjectCompanyIdGetData,
  SearchProjectApiV1AnonymizedProjectCompanyIdGetError,
  SearchReportListApiV1InsilicolabReportGetData,
  SearchReportListApiV1InsilicolabReportGetError,
  SearchSubjectApiV1AnonymizedSubjectGetData,
  SearchUsersApiV1InsilicolabOrganizationOrganizationIdGetData,
  SearchUsersApiV1InsilicolabOrganizationOrganizationIdGetError,
  UpdateCategoryApiV1InsilicolabCategoryCategoryIdPutData,
  UpdateCategoryApiV1InsilicolabCategoryCategoryIdPutError,
  UpdateCategoryScheme,
  UpdateEndDateApiV1InsilicolabReportReportReportIdPostData,
  UpdateEndDateApiV1InsilicolabReportReportReportIdPostError,
  UploadFileApiV1InsilicolabReportFilePutData,
  UploadFileApiV1InsilicolabReportFilePutError,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Auth
   * @name LoginApiV1AuthLoginGet
   * @summary Google OAuth 로그인 시작
   * @request GET:/api/v1/auth/login
   * @response `200` `LoginApiV1AuthLoginGetData` Successful Response
   */
  loginApiV1AuthLoginGet = (params: RequestParams = {}) =>
    this.request<LoginApiV1AuthLoginGetData, any>({
      path: `/api/v1/auth/login`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthApiV1AuthAuthGet
   * @summary Google OAuth 인증 후 콜백 엔드 포인트
   * @request GET:/api/v1/auth/auth
   * @response `200` `AuthApiV1AuthAuthGetData` Successful Response
   */
  authApiV1AuthAuthGet = (params: RequestParams = {}) =>
    this.request<AuthApiV1AuthAuthGetData, any>({
      path: `/api/v1/auth/auth`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LogoutApiV1AuthLogoutDelete
   * @summary 로그 아웃: 쿠키 정보 삭제
   * @request DELETE:/api/v1/auth/logout
   * @response `200` `LogoutApiV1AuthLogoutDeleteData` Successful Response
   */
  logoutApiV1AuthLogoutDelete = (params: RequestParams = {}) =>
    this.request<LogoutApiV1AuthLogoutDeleteData, any>({
      path: `/api/v1/auth/logout`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name CheckApiV1AuthCheckGet
   * @summary 쿠키 정보의 유효성 확인
   * @request GET:/api/v1/auth/check
   * @response `200` `CheckApiV1AuthCheckGetData` Successful Response
   */
  checkApiV1AuthCheckGet = (params: RequestParams = {}) =>
    this.request<CheckApiV1AuthCheckGetData, any>({
      path: `/api/v1/auth/check`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name MeApiV1AuthMeGet
   * @summary 인증된 사용자 정보 조회
   * @request GET:/api/v1/auth/me
   * @response `200` `MeApiV1AuthMeGetData` Successful Response
   */
  meApiV1AuthMeGet = (params: RequestParams = {}) =>
    this.request<MeApiV1AuthMeGetData, any>({
      path: `/api/v1/auth/me`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name EnvApiV1AuthEnvGet
   * @summary 사용자 환경 변수
   * @request GET:/api/v1/auth/env
   * @response `200` `EnvApiV1AuthEnvGetData` Successful Response
   */
  envApiV1AuthEnvGet = (params: RequestParams = {}) =>
    this.request<EnvApiV1AuthEnvGetData, any>({
      path: `/api/v1/auth/env`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name GroupListApiV1AuthGroupGet
   * @summary 설정할 수 있는 그룹 목록 조회
   * @request GET:/api/v1/auth/group
   * @response `200` `GroupListApiV1AuthGroupGetData` Successful Response
   */
  groupListApiV1AuthGroupGet = (params: RequestParams = {}) =>
    this.request<GroupListApiV1AuthGroupGetData, any>({
      path: `/api/v1/auth/group`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name GroupApiV1AuthGroupPut
   * @summary 사용자 그룹 설정
   * @request PUT:/api/v1/auth/group
   * @response `200` `GroupApiV1AuthGroupPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  groupApiV1AuthGroupPut = (data: PutGroupScheme, params: RequestParams = {}) =>
    this.request<GroupApiV1AuthGroupPutData, GroupApiV1AuthGroupPutError>({
      path: `/api/v1/auth/group`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AdminApiV1AuthAdminPut
   * @summary 사용자를 관리자로 지정
   * @request PUT:/api/v1/auth/admin
   * @response `200` `AdminApiV1AuthAdminPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  adminApiV1AuthAdminPut = (data: PutAdminScheme, params: RequestParams = {}) =>
    this.request<AdminApiV1AuthAdminPutData, AdminApiV1AuthAdminPutError>({
      path: `/api/v1/auth/admin`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Craft
   * @name SearchCraftApiV1CraftGet
   * @summary 수집 정보 조회
   * @request GET:/api/v1/craft/
   * @response `200` `SearchCraftApiV1CraftGetData` Successful Response
   */
  searchCraftApiV1CraftGet = (params: RequestParams = {}) =>
    this.request<SearchCraftApiV1CraftGetData, any>({
      path: `/api/v1/craft/`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Craft
   * @name AddDataApiV1CraftPut
   * @summary 정보 추가
   * @request PUT:/api/v1/craft/
   * @response `200` `AddDataApiV1CraftPutData` Successful Response
   */
  addDataApiV1CraftPut = (params: RequestParams = {}) =>
    this.request<AddDataApiV1CraftPutData, any>({
      path: `/api/v1/craft/`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Craft
   * @name CraftStatisticsApiV1CraftStatisticsGet
   * @summary 수집 정보 통계 조회 (그래프용)
   * @request GET:/api/v1/craft/statistics
   * @response `200` `CraftStatisticsApiV1CraftStatisticsGetData` Successful Response
   */
  craftStatisticsApiV1CraftStatisticsGet = (params: RequestParams = {}) =>
    this.request<CraftStatisticsApiV1CraftStatisticsGetData, any>({
      path: `/api/v1/craft/statistics`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Category
   * @name GetAllCategoryApiV1InsilicolabCategoryGet
   * @summary 전체 카테고리 조회
   * @request GET:/api/v1/insilicolab/category/
   * @response `200` `GetAllCategoryApiV1InsilicolabCategoryGetData` Successful Response
   */
  getAllCategoryApiV1InsilicolabCategoryGet = (params: RequestParams = {}) =>
    this.request<GetAllCategoryApiV1InsilicolabCategoryGetData, any>({
      path: `/api/v1/insilicolab/category/`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Category
   * @name AddCategoryApiV1InsilicolabCategoryPost
   * @summary 카테고리 등록
   * @request POST:/api/v1/insilicolab/category/
   * @response `200` `AddCategoryApiV1InsilicolabCategoryPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addCategoryApiV1InsilicolabCategoryPost = (data: AddCategoryScheme, params: RequestParams = {}) =>
    this.request<AddCategoryApiV1InsilicolabCategoryPostData, AddCategoryApiV1InsilicolabCategoryPostError>({
      path: `/api/v1/insilicolab/category/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Category
   * @name GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGet
   * @summary 카테고리 조회
   * @request GET:/api/v1/insilicolab/category/{category_id}
   * @response `200` `GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getCategoryByIdApiV1InsilicolabCategoryCategoryIdGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<
      GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGetData,
      GetCategoryByIdApiV1InsilicolabCategoryCategoryIdGetError
    >({
      path: `/api/v1/insilicolab/category/${categoryId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Category
   * @name UpdateCategoryApiV1InsilicolabCategoryCategoryIdPut
   * @summary 카테고리 수정
   * @request PUT:/api/v1/insilicolab/category/{category_id}
   * @response `200` `UpdateCategoryApiV1InsilicolabCategoryCategoryIdPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  updateCategoryApiV1InsilicolabCategoryCategoryIdPut = (
    categoryId: number,
    data: UpdateCategoryScheme,
    params: RequestParams = {},
  ) =>
    this.request<
      UpdateCategoryApiV1InsilicolabCategoryCategoryIdPutData,
      UpdateCategoryApiV1InsilicolabCategoryCategoryIdPutError
    >({
      path: `/api/v1/insilicolab/category/${categoryId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Category
   * @name DeleteCategoryApiV1InsilicolabCategoryCategoryIdDelete
   * @summary 카테고리 삭제
   * @request DELETE:/api/v1/insilicolab/category/{category_id}
   * @response `200` `DeleteCategoryApiV1InsilicolabCategoryCategoryIdDeleteData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  deleteCategoryApiV1InsilicolabCategoryCategoryIdDelete = (categoryId: number, params: RequestParams = {}) =>
    this.request<
      DeleteCategoryApiV1InsilicolabCategoryCategoryIdDeleteData,
      DeleteCategoryApiV1InsilicolabCategoryCategoryIdDeleteError
    >({
      path: `/api/v1/insilicolab/category/${categoryId}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Environment
   * @name CountriesApiV1InsilicolabEnvironmentCountryGet
   * @summary 국가 코드 조회
   * @request GET:/api/v1/insilicolab/environment/country
   * @response `200` `CountriesApiV1InsilicolabEnvironmentCountryGetData` Successful Response
   */
  countriesApiV1InsilicolabEnvironmentCountryGet = (params: RequestParams = {}) =>
    this.request<CountriesApiV1InsilicolabEnvironmentCountryGetData, any>({
      path: `/api/v1/insilicolab/environment/country`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name SearchOrganizationsApiV1InsilicolabOrganizationGet
   * @summary 그룹 정보 조회
   * @request GET:/api/v1/insilicolab/organization/
   * @response `200` `SearchOrganizationsApiV1InsilicolabOrganizationGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchOrganizationsApiV1InsilicolabOrganizationGet = (
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      SearchOrganizationsApiV1InsilicolabOrganizationGetData,
      SearchOrganizationsApiV1InsilicolabOrganizationGetError
    >({
      path: `/api/v1/insilicolab/organization/`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name AddOrganizationApiV1InsilicolabOrganizationPost
   * @summary 그룹 생성
   * @request POST:/api/v1/insilicolab/organization/
   * @response `200` `AddOrganizationApiV1InsilicolabOrganizationPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addOrganizationApiV1InsilicolabOrganizationPost = (data: CreateOrgScheme, params: RequestParams = {}) =>
    this.request<
      AddOrganizationApiV1InsilicolabOrganizationPostData,
      AddOrganizationApiV1InsilicolabOrganizationPostError
    >({
      path: `/api/v1/insilicolab/organization/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDelete
   * @summary 그룹 비활성화
   * @request DELETE:/api/v1/insilicolab/organization/{organization_id}
   * @response `200` `DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDeleteData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  deleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDelete = (
    organizationId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDeleteData,
      DeleteOrganizationApiV1InsilicolabOrganizationOrganizationIdDeleteError
    >({
      path: `/api/v1/insilicolab/organization/${organizationId}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name AddUserApiV1InsilicolabOrganizationOrganizationIdPost
   * @summary User 생성
   * @request POST:/api/v1/insilicolab/organization/{organization_id}
   * @response `200` `AddUserApiV1InsilicolabOrganizationOrganizationIdPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addUserApiV1InsilicolabOrganizationOrganizationIdPost = (
    organizationId: string,
    data: CreateUserScheme,
    params: RequestParams = {},
  ) =>
    this.request<
      AddUserApiV1InsilicolabOrganizationOrganizationIdPostData,
      AddUserApiV1InsilicolabOrganizationOrganizationIdPostError
    >({
      path: `/api/v1/insilicolab/organization/${organizationId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name SearchUsersApiV1InsilicolabOrganizationOrganizationIdGet
   * @summary 사용자 목록 조회
   * @request GET:/api/v1/insilicolab/organization/{organization_id}
   * @response `200` `SearchUsersApiV1InsilicolabOrganizationOrganizationIdGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchUsersApiV1InsilicolabOrganizationOrganizationIdGet = (
    organizationId: string,
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      SearchUsersApiV1InsilicolabOrganizationOrganizationIdGetData,
      SearchUsersApiV1InsilicolabOrganizationOrganizationIdGetError
    >({
      path: `/api/v1/insilicolab/organization/${organizationId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDelete
   * @summary 사용자 활성화 상태 변경
   * @request DELETE:/api/v1/insilicolab/organization/{organization_id}/{user_id}
   * @response `200` `RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDeleteData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeUserApiV1InsilicolabOrganizationOrganizationIdUserIdDelete = (
    organizationId: string,
    userId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDeleteData,
      RemoveUserApiV1InsilicolabOrganizationOrganizationIdUserIdDeleteError
    >({
      path: `/api/v1/insilicolab/organization/${organizationId}/${userId}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Organization
   * @name ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPost
   * @summary 사용자 비번 변경
   * @request POST:/api/v1/insilicolab/organization/{organization_id}/{user_id}
   * @response `200` `ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  changeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPost = (
    organizationId: string,
    userId: string,
    data: ChangePasswordScheme,
    params: RequestParams = {},
  ) =>
    this.request<
      ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPostData,
      ChangeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPostError
    >({
      path: `/api/v1/insilicolab/organization/${organizationId}/${userId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Report
   * @name SearchReportListApiV1InsilicolabReportGet
   * @summary 리포트 목록 조회
   * @request GET:/api/v1/insilicolab/report/
   * @response `200` `SearchReportListApiV1InsilicolabReportGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchReportListApiV1InsilicolabReportGet = (
    query?: {
      /**
       * Page
       * @default 1
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<SearchReportListApiV1InsilicolabReportGetData, SearchReportListApiV1InsilicolabReportGetError>({
      path: `/api/v1/insilicolab/report/`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Report
   * @name UploadFileApiV1InsilicolabReportFilePut
   * @summary 파일 업로드 (PDF/IMAGE/RAW)
   * @request PUT:/api/v1/insilicolab/report/file
   * @response `200` `UploadFileApiV1InsilicolabReportFilePutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadFileApiV1InsilicolabReportFilePut = (
    data: BodyUploadFileApiV1InsilicolabReportFilePut,
    params: RequestParams = {},
  ) =>
    this.request<UploadFileApiV1InsilicolabReportFilePutData, UploadFileApiV1InsilicolabReportFilePutError>({
      path: `/api/v1/insilicolab/report/file`,
      method: "PUT",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab - Report
   * @name UpdateEndDateApiV1InsilicolabReportReportReportIdPost
   * @summary 리포트 사용 기간 변경
   * @request POST:/api/v1/insilicolab/report/report/{report_id}
   * @response `200` `UpdateEndDateApiV1InsilicolabReportReportReportIdPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  updateEndDateApiV1InsilicolabReportReportReportIdPost = (
    reportId: string,
    data: ReportExpireScheme,
    params: RequestParams = {},
  ) =>
    this.request<
      UpdateEndDateApiV1InsilicolabReportReportReportIdPostData,
      UpdateEndDateApiV1InsilicolabReportReportReportIdPostError
    >({
      path: `/api/v1/insilicolab/report/report/${reportId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name GetBucketListApiV1MinioBucketGet
   * @summary Bucket 목록 조회
   * @request GET:/api/v1/minio/bucket
   * @response `200` `GetBucketListApiV1MinioBucketGetData` Successful Response
   */
  getBucketListApiV1MinioBucketGet = (params: RequestParams = {}) =>
    this.request<GetBucketListApiV1MinioBucketGetData, any>({
      path: `/api/v1/minio/bucket`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name AddBucketApiV1MinioBucketPost
   * @summary Bucket 생성
   * @request POST:/api/v1/minio/bucket
   * @response `200` `AddBucketApiV1MinioBucketPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addBucketApiV1MinioBucketPost = (data: MinIOCreateBucketScheme, params: RequestParams = {}) =>
    this.request<AddBucketApiV1MinioBucketPostData, AddBucketApiV1MinioBucketPostError>({
      path: `/api/v1/minio/bucket`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name PostCreateDirectoryApiV1MinioDirectoryPost
   * @summary 디렉토리 생성
   * @request POST:/api/v1/minio/directory
   * @response `200` `PostCreateDirectoryApiV1MinioDirectoryPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  postCreateDirectoryApiV1MinioDirectoryPost = (data: MinIOCreatePathScheme, params: RequestParams = {}) =>
    this.request<PostCreateDirectoryApiV1MinioDirectoryPostData, PostCreateDirectoryApiV1MinioDirectoryPostError>({
      path: `/api/v1/minio/directory`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name GetDirectoryListApiV1MinioDirectoryBucketNameGet
   * @summary 디렉토리 목록 조회
   * @request GET:/api/v1/minio/directory/{bucket_name}
   * @response `200` `GetDirectoryListApiV1MinioDirectoryBucketNameGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getDirectoryListApiV1MinioDirectoryBucketNameGet = (bucketName: string, params: RequestParams = {}) =>
    this.request<
      GetDirectoryListApiV1MinioDirectoryBucketNameGetData,
      GetDirectoryListApiV1MinioDirectoryBucketNameGetError
    >({
      path: `/api/v1/minio/directory/${bucketName}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name GetObjectListApiV1MinioObjectGet
   * @summary 파일 목록 조회
   * @request GET:/api/v1/minio/object
   * @response `200` `GetObjectListApiV1MinioObjectGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getObjectListApiV1MinioObjectGet = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Prefix Path */
      prefix_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetObjectListApiV1MinioObjectGetData, GetObjectListApiV1MinioObjectGetError>({
      path: `/api/v1/minio/object`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name RemoveObjectApiV1MinioObjectDelete
   * @summary 파일 삭제
   * @request DELETE:/api/v1/minio/object
   * @response `200` `RemoveObjectApiV1MinioObjectDeleteData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeObjectApiV1MinioObjectDelete = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Object Path */
      object_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<RemoveObjectApiV1MinioObjectDeleteData, RemoveObjectApiV1MinioObjectDeleteError>({
      path: `/api/v1/minio/object`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MinIO
   * @name GetObjectInfoApiV1MinioObjectInfoGet
   * @summary 파일 정보 조회
   * @request GET:/api/v1/minio/object/info
   * @response `200` `GetObjectInfoApiV1MinioObjectInfoGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getObjectInfoApiV1MinioObjectInfoGet = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Object Path */
      object_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetObjectInfoApiV1MinioObjectInfoGetData, GetObjectInfoApiV1MinioObjectInfoGetError>({
      path: `/api/v1/minio/object/info`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchCompanyApiV1AnonymizedCompanyGet
   * @summary 가명화 회사 정보 검색
   * @request GET:/api/v1/anonymized/company
   * @response `200` `SearchCompanyApiV1AnonymizedCompanyGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchCompanyApiV1AnonymizedCompanyGet = (
    query?: {
      /** Name */
      name?: string | null;
      /** Code */
      code?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<SearchCompanyApiV1AnonymizedCompanyGetData, SearchCompanyApiV1AnonymizedCompanyGetError>({
      path: `/api/v1/anonymized/company`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddCompanyApiV1AnonymizedCompanyPut
   * @summary 가명화 회사 정보 추가
   * @request PUT:/api/v1/anonymized/company
   * @response `200` `AddCompanyApiV1AnonymizedCompanyPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addCompanyApiV1AnonymizedCompanyPut = (data: AnCompanyRequestScheme, params: RequestParams = {}) =>
    this.request<AddCompanyApiV1AnonymizedCompanyPutData, AddCompanyApiV1AnonymizedCompanyPutError>({
      path: `/api/v1/anonymized/company`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchProjectApiV1AnonymizedProjectCompanyIdGet
   * @summary 가명화 프로젝트 정보 검색
   * @request GET:/api/v1/anonymized/project/{company_id}
   * @response `200` `SearchProjectApiV1AnonymizedProjectCompanyIdGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchProjectApiV1AnonymizedProjectCompanyIdGet = (companyId: string, params: RequestParams = {}) =>
    this.request<
      SearchProjectApiV1AnonymizedProjectCompanyIdGetData,
      SearchProjectApiV1AnonymizedProjectCompanyIdGetError
    >({
      path: `/api/v1/anonymized/project/${companyId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddProjectApiV1AnonymizedProjectCompanyIdPut
   * @summary 가명화 프로젝트 정보 추가
   * @request PUT:/api/v1/anonymized/project/{company_id}
   * @response `200` `AddProjectApiV1AnonymizedProjectCompanyIdPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addProjectApiV1AnonymizedProjectCompanyIdPut = (
    companyId: string,
    data: AnonProjectRequestScheme,
    params: RequestParams = {},
  ) =>
    this.request<AddProjectApiV1AnonymizedProjectCompanyIdPutData, AddProjectApiV1AnonymizedProjectCompanyIdPutError>({
      path: `/api/v1/anonymized/project/${companyId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchPlatformApiV1AnonymizedPlatformGet
   * @summary 가명화 플랫폼 정보 검색
   * @request GET:/api/v1/anonymized/platform
   * @response `200` `SearchPlatformApiV1AnonymizedPlatformGetData` Successful Response
   */
  searchPlatformApiV1AnonymizedPlatformGet = (params: RequestParams = {}) =>
    this.request<SearchPlatformApiV1AnonymizedPlatformGetData, any>({
      path: `/api/v1/anonymized/platform`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchGeneTypeApiV1AnonymizedGeneGet
   * @summary 가명화 조직 종류 정보 검색
   * @request GET:/api/v1/anonymized/gene
   * @response `200` `SearchGeneTypeApiV1AnonymizedGeneGetData` Successful Response
   */
  searchGeneTypeApiV1AnonymizedGeneGet = (params: RequestParams = {}) =>
    this.request<SearchGeneTypeApiV1AnonymizedGeneGetData, any>({
      path: `/api/v1/anonymized/gene`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddGeneTypeApiV1AnonymizedGenePut
   * @summary 가명화 조직 종류 정보 추가
   * @request PUT:/api/v1/anonymized/gene
   * @response `200` `AddGeneTypeApiV1AnonymizedGenePutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addGeneTypeApiV1AnonymizedGenePut = (data: AnonGeneProcessRequestScheme, params: RequestParams = {}) =>
    this.request<AddGeneTypeApiV1AnonymizedGenePutData, AddGeneTypeApiV1AnonymizedGenePutError>({
      path: `/api/v1/anonymized/gene`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchGenePanelApiV1AnonymizedGeneGeneIdGet
   * @summary 가명화 조직 패널 정보 검색
   * @request GET:/api/v1/anonymized/gene/{gene_id}
   * @response `200` `SearchGenePanelApiV1AnonymizedGeneGeneIdGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchGenePanelApiV1AnonymizedGeneGeneIdGet = (geneId: string, params: RequestParams = {}) =>
    this.request<SearchGenePanelApiV1AnonymizedGeneGeneIdGetData, SearchGenePanelApiV1AnonymizedGeneGeneIdGetError>({
      path: `/api/v1/anonymized/gene/${geneId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddGenePanelApiV1AnonymizedGeneGeneIdPut
   * @summary 가명화 조직 패널 정보 추가
   * @request PUT:/api/v1/anonymized/gene/{gene_id}
   * @response `200` `AddGenePanelApiV1AnonymizedGeneGeneIdPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addGenePanelApiV1AnonymizedGeneGeneIdPut = (
    geneId: string,
    data: AnonGenePanelRequestScheme,
    params: RequestParams = {},
  ) =>
    this.request<AddGenePanelApiV1AnonymizedGeneGeneIdPutData, AddGenePanelApiV1AnonymizedGeneGeneIdPutError>({
      path: `/api/v1/anonymized/gene/${geneId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchSubjectApiV1AnonymizedSubjectGet
   * @summary 검사 종류 정보 검색
   * @request GET:/api/v1/anonymized/subject
   * @response `200` `SearchSubjectApiV1AnonymizedSubjectGetData` Successful Response
   */
  searchSubjectApiV1AnonymizedSubjectGet = (params: RequestParams = {}) =>
    this.request<SearchSubjectApiV1AnonymizedSubjectGetData, any>({
      path: `/api/v1/anonymized/subject`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddSubjectApiV1AnonymizedSubjectPut
   * @summary 검사 종류 정보 추가
   * @request PUT:/api/v1/anonymized/subject
   * @response `200` `AddSubjectApiV1AnonymizedSubjectPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addSubjectApiV1AnonymizedSubjectPut = (data: AnonSubjectRequestScheme, params: RequestParams = {}) =>
    this.request<AddSubjectApiV1AnonymizedSubjectPutData, AddSubjectApiV1AnonymizedSubjectPutError>({
      path: `/api/v1/anonymized/subject`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchDiseaseApiV1AnonymizedDiseaseGet
   * @summary 질병 종류 정보 검색
   * @request GET:/api/v1/anonymized/disease
   * @response `200` `SearchDiseaseApiV1AnonymizedDiseaseGetData` Successful Response
   */
  searchDiseaseApiV1AnonymizedDiseaseGet = (params: RequestParams = {}) =>
    this.request<SearchDiseaseApiV1AnonymizedDiseaseGetData, any>({
      path: `/api/v1/anonymized/disease`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddDiseaseApiV1AnonymizedDiseasePut
   * @summary 질병 종류 정보 추가
   * @request PUT:/api/v1/anonymized/disease
   * @response `200` `AddDiseaseApiV1AnonymizedDiseasePutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addDiseaseApiV1AnonymizedDiseasePut = (data: AnonDiseaseRequestScheme, params: RequestParams = {}) =>
    this.request<AddDiseaseApiV1AnonymizedDiseasePutData, AddDiseaseApiV1AnonymizedDiseasePutError>({
      path: `/api/v1/anonymized/disease`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name SearchBlockApiV1AnonymizedBlockGet
   * @summary 블록 정보 검색
   * @request GET:/api/v1/anonymized/block
   * @response `200` `SearchBlockApiV1AnonymizedBlockGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  searchBlockApiV1AnonymizedBlockGet = (
    query?: {
      /**
       * Page
       * @default 1
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<SearchBlockApiV1AnonymizedBlockGetData, SearchBlockApiV1AnonymizedBlockGetError>({
      path: `/api/v1/anonymized/block`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Anonymized
   * @name AddBlockApiV1AnonymizedBlockPut
   * @summary 블록 정보 추가
   * @request PUT:/api/v1/anonymized/block
   * @response `200` `AddBlockApiV1AnonymizedBlockPutData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addBlockApiV1AnonymizedBlockPut = (data: AnBlockRequestScheme, params: RequestParams = {}) =>
    this.request<AddBlockApiV1AnonymizedBlockPutData, AddBlockApiV1AnonymizedBlockPutError>({
      path: `/api/v1/anonymized/block`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
