import React from "react";
import { Button, ConfigProvider, Layout, Typography } from "antd";
import Header from "../../layouts/Header";
import { LoginOutlined } from "@ant-design/icons";
import Footer from "../../layouts/Footer";
import { createStyles } from "antd-style";
import { API_BASE_URL } from "../../libs/api/RequestAPI";

const ContentWrapper = Layout.Content;

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: "";
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

const Login = () => {
  const { styles } = useStyle();

  const handleLogin = () => {
    window.location.href = API_BASE_URL + "/api/v1/auth/login";
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header />
      <Layout
        style={{
          padding: "0 24px",
          backgroundImage: "url(/bg-white.png)",
        }}
      >
        <ContentWrapper style={{ padding: "50px" }}>
          <div style={{ textAlign: "center" }}>
            <Typography>
              <h1
                style={{
                  fontSize: "7em",
                  fontWeight: "bold",
                  color: "#3c4249",
                }}
              >
                Solutionist of Biopharmaceutical Industry
              </h1>
            </Typography>

            <ConfigProvider
              button={{
                className: styles.linearGradientButton,
              }}
            >
              <Button
                type="primary"
                size="large"
                icon={<LoginOutlined />}
                onClick={handleLogin}
              >
                Login MIRO
              </Button>
            </ConfigProvider>
          </div>
        </ContentWrapper>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Login;
