import { ColumnsType } from "antd/es/table";
import { AnBlockScheme } from "../../../libs/api/data-contracts";

export const STDBColumn: ColumnsType<AnBlockScheme> = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    align: "center",
  },
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
    align: "center",
  },
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
    align: "center",
  },
  {
    title: "Gene Type",
    dataIndex: "gene_type",
    key: "geneType",
    align: "center",
  },
  {
    title: "Gene panel",
    dataIndex: "gene_panel",
    key: "genePanel",
    align: "center",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    align: "center",
  },
  {
    title: "Disease",
    dataIndex: "disease",
    key: "disease",
    align: "center",
  },
  {
    title: "Anonymized ID",
    dataIndex: "block_id",
    key: "block_id",
    align: "center",
  },
];
