import { Route, Routes, Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import User from "../pages/InSilicoLab/User";
import Report from "../pages/InSilicoLab/Report";
import CategoryManagement from "../pages/InSilicoLab/Category";
import MinIO from "../pages/Storage/MinIO";
import MinObject from "../pages/Storage/MinObject";
import SpatialDB from "../pages/Miro/STDB";
import STData from "../pages/Miro/STData";
import SpatialSlide from "../pages/Miro/STSlide";
import NotFoundPage from "../pages/NotFoundPage";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />

        {/* InSilicoLab */}
        <Route
          path="/insilicolab"
          element={<Navigate to="/insilicolab/user" replace />}
        />
        <Route path="/insilicolab/user" element={<User />} />
        <Route path="/insilicolab/report" element={<Report />} />
        <Route path="/insilicolab/category" element={<CategoryManagement />} />

        {/* MIRO */}
        <Route path="/miro/stdb" element={<SpatialDB />} />
        <Route path="/miro/stdb/:anonymizedId" element={<SpatialSlide />} />
        <Route path="/miro/stdb/:anonymizedId/:slideId" element={<STData />} />

        {/* Storage */}
        <Route
          path="/storage"
          element={<Navigate to="/storage/minio" replace />}
        />
        <Route path="/storage/minio" element={<MinIO />} />
        <Route path="/storage/minio/*" element={<MinObject />} />

        {/* if not match any path */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;
