import React from "react";
import { Modal, Input, Form } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";

interface PasswordModalProps {
  visible: boolean;
  organizationId: string | null;
  userId: string | null;
  onClose: () => void;
}

const ResetUserPasswordModal: React.FC<PasswordModalProps> = ({
  visible,
  organizationId,
  userId,
  onClose,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data: any = {
          password: values.password,
        };
        requestAuthApi
          .changeUserPasswordApiV1InsilicolabOrganizationOrganizationIdUserIdPost(
            values.organizationId,
            values.userId,
            data,
          )
          .then(() => {
            form.resetFields();
            onClose();
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Change Password"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="organizationId"
          initialValue={organizationId}
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item name="userId" initialValue={userId} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            { required: true, message: "Please input the new password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetUserPasswordModal;
