import React, { useEffect, useState } from "react";
import { message, Modal, Space, Upload } from "antd";
import { Button, Input, Form, Select } from "antd";
import { requestAuthApi } from "../../../libs/api/RequestAPI";
import { BodyUploadFileApiV1InsilicolabReportFilePut } from "../../../libs/api/data-contracts";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const RAWUploadModal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [orgData, setOrgData] = useState<any[]>([]);
  const [userData, setUserData] = useState<any[]>([]);
  const [speciesData, setSpeciesData] = useState<any[]>([]);
  const [organData, setOrganData] = useState<any[]>([]);
  const [bioStatusData, setBioStatusData] = useState<any[]>([]);

  useEffect(() => {
    const setDefault = async () => {
      try {
        const orgResponse =
          await requestAuthApi.searchOrganizationsApiV1InsilicolabOrganizationGet(
            { page: 0, size: 1000 },
          );
        setOrgData(
          orgResponse.data.map((org) => ({
            value: org.organization_id,
            label: org.organization_name,
          })),
        );

        const response =
          await requestAuthApi.getCategoryByIdApiV1InsilicolabCategoryCategoryIdGet(
            0,
          );
        setSpeciesData(
          response.data.map((category) => ({
            value: category.key,
            label: category.name,
          })),
        );
      } catch (error) {
        message.error("bucket 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    setDefault();
  }, []);

  const updateUserData = async (values: any) => {
    const responseUser =
      await requestAuthApi.searchUsersApiV1InsilicolabOrganizationOrganizationIdGet(
        values,
      );
    setUserData(
      responseUser.data.map((user) => ({
        value: user.user_id,
        label: user.first_name + " " + user.last_name,
      })),
    );
  };

  const updateSpeciesData = async (values: any) => {
    const response =
      await requestAuthApi.getCategoryByIdApiV1InsilicolabCategoryCategoryIdGet(
        values,
      );
    setOrganData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const updateOrganData = async (values: any) => {
    const response =
      await requestAuthApi.getCategoryByIdApiV1InsilicolabCategoryCategoryIdGet(
        values,
      );
    setBioStatusData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const onFinish = async (values: any) => {
    try {
      const data: BodyUploadFileApiV1InsilicolabReportFilePut = {
        report_file: fileList[0].originFileObj,
        organization_id: values.organization_id,
        user_id: values.user_id,
        project_name: values.project_name,
        service_type: values.service_type,
        report_type: values.report_type,
        species: values.species,
        organ: values.organ,
        bio_status: values.bio_status,
      };
      await requestAuthApi.uploadFileApiV1InsilicolabReportFilePut(data);
      window.location.reload();
    } catch (error) {
      message.error("Interactive Report 등록 실패");
      console.error("Failed to fetch data", error);
    }
  };

  const handleFileChange = ({ fileList }: any) =>
    setFileList(fileList.slice(-1));

  return (
    <Modal
      title="Add Report Data"
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        {...layout}
        name="upload_report_file"
        onFinish={onFinish}
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <Form.Item
          name="organization_id"
          label="organization"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={orgData} onSelect={updateUserData} />
        </Form.Item>

        <Form.Item
          name="user_id"
          label="user"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={userData} />
        </Form.Item>

        <Form.Item
          name="project_name"
          label="Project Name"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="service_type"
          label="Service Type"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { value: "MIX", label: "MIX" },
              { value: "MOA", label: "MOA" },
              { value: "TARGET", label: "TARGET" },
              { value: "DRUG", label: "DRUG" },
              { value: "DISCOVERY", label: "DISCOVERY" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="report_type"
          label="Report Type"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { value: "PDF", label: "PDF" },
              { value: "DATA", label: "DATA" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="species"
          label="Species"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={speciesData} onSelect={updateSpeciesData} />
        </Form.Item>

        <Form.Item
          name="organ"
          label="Organ"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={organData} onSelect={updateOrganData} />
        </Form.Item>

        <Form.Item
          name="bio_status"
          label="Bio Status"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={bioStatusData} />
        </Form.Item>

        <Form.Item label="Upload">
          <Upload
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <Button>Select File</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RAWUploadModal;
