import { GetObject } from "../../../libs/minio/MinIOClient";

import { message } from "antd";

const MinIODownload = async (bucketName: string, objectName: string) => {
  try {
    message.success("파일 다운로드가 시작 되었습니다. 잠시 기다려 주세요");
    await GetObject(bucketName, objectName);
  } catch (error) {
    message.error("다운로드 에러");
    console.log(error);
  }
};

export default MinIODownload;
